import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import GoogleButton from 'react-google-button'
import { Navbar, Container, Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FeatureList from '../components/features';
function Home() {
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');

  const [companySize, setCompanySize] = useState('');
  const [industry, setIndustry] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(null);
  const [isNewUser, setIsNewUser] = useState(true);

  const industries = [
    "Agriculture",
    "Automotive",
    "Construction",
    "Finance",
    "Healthcare",
    "Information Technology",
    "Retail",
    "Transportation",
    "Other"

  ];

  function generatePassword(length = 8) {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    if (isNewUser && email !== confirmEmail) {
      setError("Emails do not match!");
      return;
    }

    try {
      if (isNewUser) {
        // TODO: Send the user details to your backend or Firebase Firestore
        const password = generatePassword(10);  // For a 10-character password

        await createUserWithEmailAndPassword(auth, email, password);

        // ... 
        const db = getFirestore();
        const userRef = doc(db, "users", auth.currentUser.uid);
        await setDoc(userRef, {
          fullName: fullName,
          industry: industry,
          industry: industry,
          email: email,
          companySize: companySize,
          phoneNumber: phoneNumber

          // ... other fields
        });

        // TODO: Send autogenerated password to the provided email
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (



    <div className=" text-dark" style={{ height: '100vh' }}>
      {/* Navigation Bar */}

      {/* Main Content */}

      <section className="jumbotron text-center bg-g1">
        <div className="container">
          <div className="row">
            {/* Left column for text */}
            <div className="row">
              {/* Left column for text */}
              <div className="col-md-12">
                <h1 className="display-4">Unleash Your Creative Vision with Tailor-Made Video Stories for All Ages</h1>
                <p className="lead text-muted">
                  We empower storytellers, educators, and imaginative minds to effortlessly transform their ideas into captivating video narratives. Our platform takes your spark of inspiration and weaves it into rich, engaging stories complete with captivating titles and stunning visuals. It's storytelling redefined, where you don't just create stories – you craft immersive story videos with unique graphics that enchant viewers of all ages. Ideal for YouTubers, parents, and children's authors seeking to breathe life into their stories like never before.    </p>
                <Link className="btn btn-success btn-lg my-2" to='/signin'>Start Your Creative Journey Today!</Link>
              </div>
            </div>


          </div>
        </div>
      </section>

      <Container className='p-4'>
        <Row>

          <h5>Create Your Own Characters and Craft Exciting Video Stories for Kids!</h5>
          <p>Ignite your child's creativity with just a few lines! Our platform makes it a breeze to create captivating characters and scenes for stories, posters, and more. Experience the joy of crafting with ease and watch their imagination soar!</p>

          <Col xs={12} sm={6} md={4} lg={2} className='mt-4'>
            <Card>
              <Card.Img variant="top" src="photos/lCDogFTpIB85GpOauGb3.jpg" />
              {/* Other Card content here */}
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={2} className='mt-4'>
            <Card>
              <Card.Img variant="top" src="photos/UHZBUvgRxPklRNtVlkJv.jpg" />
              {/* Other Card content here */}
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={2} className='mt-4'>
            <Card>
              <Card.Img variant="top" src="photos/UXzkh5a8SN2EbyLRYGlC.jpg" />
              {/* Other Card content here */}
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={2} className='mt-4'>
            <Card>
              <Card.Img variant="top" src="photos/VFql0AxatDXxcecPPTd0.jpg" />
              {/* Other Card content here */}
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={2} className='mt-4'>
            <Card>
              <Card.Img variant="top" src="photos/QuzcRTdrikxrqoYeTYYT.jpg" />
              {/* Other Card content here */}
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} lg={2} className='mt-4'>
            <Card>
              <Card.Img variant="top" src="photos/dYUbTedPhSS2nxAeuTPZ.jpg" />
              {/* Other Card content here */}
            </Card>
          </Col>
        </Row>
      </Container>


      <Container className='p-4'>
        <h5>Effortless Storytelling: Transforming Words into Enchanting Tales</h5>
        <p>Unlock the enchanting world of storytelling with ease on our platform. Just provide a few words for your plot, and watch as we transform them into stunning stories that captivate and delight. Creating magical tales for kids has never been simpler!</p>
        <Row>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/HU1JR_av7lE?si=0iCljl87955VrQCQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/q9T0EFUsB9c?si=u3mmAETfYLOn6B9j" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/K2BClFgrkKw?si=YPogxB-M65DnJWJd" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  <div className="embed-responsive embed-responsive-16by9">

                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/JXUauOJnOqo?si=eWb9O3Fy0z04_AMt" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  <div className="embed-responsive embed-responsive-16by9">

                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/5PwS9hntMKg?si=cygiTgY3c7R5R7gE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/4JkF1tkhId0?si=SpotEU7e4yKQuXwk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>


        <Row>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  <div className="embed-responsive embed-responsive-16by9">

                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/aPVlZ6mjnFs?si=k170b8NhLfPBY3D9" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/TsgqilVQjfc?si=wX7ckk1sChJbu_ZH" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/QxKKxe29XNM?si=-Edze5D_tatOr1zg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/2zcVZVYRDAU?si=smFpFOdEqVOCYG1Y" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/kZyM-1mJM-w?si=MGqWA3H7VZWHtNmF" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/v_MhHjgNrsg?si=uXtE4s6rIlygfwLi" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  <div className="embed-responsive embed-responsive-16by9">

                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/sqx9B3WbeSE?si=w61623JtkpwLae9Q" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/4yK9tsfK7xk?si=j4TOSiaOP_uf2bsG" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/t0Ip73Esq6k?si=ioJZaKJVMvRCrXyr" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
      </Container>


      <Container className='p-4' >

        <Container className='p-4'>
          <Row>
            <h5>Edible Artistry: Craft Your Dream Cake with Our Imaginative Creations</h5>
            <p>Ever dreamt of a cake that's as unique as your imagination? Let our platform turn your visions into delicious reality! Whether it's a birthday, wedding, or any special occasion, we can generate stunning cake designs that you can share with your favorite cake maker. Create a one-of-a-kind masterpiece that's not only scrumptious but also a feast for the eyes. Taste the artistry, one slice at a time!</p>

            <Col xs={12} sm={6} md={4} lg={2} className='mt-4'>
              <Card>
                <Card.Img variant="top" src="photos/53eJBzKEsxHXNofF3odq.jpg" />
                {/* Other Card content here */}
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} lg={2} className='mt-4'>
              <Card>
                <Card.Img variant="top" src="photos/NbqakYw205eLuO0grIvl.jpg" />
                {/* Other Card content here */}
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} lg={2} className='mt-4'>
              <Card>
                <Card.Img variant="top" src="photos/ena3h1qNphuGTl2LHByE.jpg" />
                {/* Other Card content here */}
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} lg={2} className='mt-4'>
              <Card>
                <Card.Img variant="top" src="photos/uPmQrve2JaPZ22LI3nYH.jpg" />
                {/* Other Card content here */}
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} lg={2} className='mt-4'>
              <Card>
                <Card.Img variant="top" src="photos/UIqmZX3Zy8OeLC2ltPVz.jpg" />
                {/* Other Card content here */}
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} lg={2} className='mt-4'>
              <Card>
                <Card.Img variant="top" src="photos/XPDGD2f3trvDylskSNRH.jpg" />
                {/* Other Card content here */}
              </Card>
            </Col>
          </Row>





        </Container>

        <Row className=''>
          <h5>Features</h5>
          <div className="mt-4">
            <FeatureList />
          </div>
        </Row>
      </Container>

      <Container className='p-4'>

        <Row>
          <h5>What Our Users Say</h5>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  "Creating kids' content on YouTube has never been more inspiring. DocTran AI's app turns my ideas into magical video stories that my audience loves!" - <em>Katie J., Kids Content Creator</em>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  "As a children's author, the transition from pen and paper to digital storytelling was seamless with DocTran AI. My stories are now vibrant videos that engage children globally." - <em>Michael T., Children's Book Author</em>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mt-4'>
            <Card className='h-100'>
              <Card.Body>
                <Card.Text>
                  "My parenting blog has taken a delightful turn with interactive story videos from DocTran AI, making storytelling sessions so much more engaging for both me and my kids!" - <em>Sofia R., Parenting Blogger</em>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>



      {/* <Container className='bg-light p-4 mt-4 '>

        <Row className='mt-4'>


          <h5>Instant Story Creation: From Plot to Production in a Click</h5>
          <p className="lead text-muted">Bring your stories to life with unparalleled ease using our innovative platform. Just submit your plot outline, and watch as our real-time processing transforms your ideas into a complete multimedia package. Observe each step as your story unfolds through automated text generation, engaging voiceovers, and dynamic video creation. Upon completion, you'll have immediate access to download all assets, including high-quality MP4 videos, crystal-clear MP3 voiceovers, and the full text of your story. It's storytelling made simple, fast, and entirely at your fingertips.</p>
          <img src='./screenshot.png'></img>


        </Row>
      </Container> */}


      <Container className='bg-light p-4 mt-4 '>

        <Row className='mt-4'>


          <h5>Contact us</h5>
          <p className="lead text-muted">Beyond stories for children, DocTran AI offers tailored content generation solutions for various needs. If you're looking to harness the power of AI for content creation, reach out to us at info@doctran.ai, and let's bring your vision to life!</p>



        </Row>
      </Container>







      <Navbar bg="dark mt-4" variant="dark">
        <Container>
          <Navbar.Brand href="#home">

            <img src="logo-white.png" width="30" height="30" className="d-inline-block align-top mr-1" alt=" " />
            DOCTRAN.ai
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text className='text-light p-2'>
              info@doctran.ai
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>


    </div >

  );

}

export default Home;
