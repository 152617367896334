

import Navbar from './components/Navbar';
import Protected from './components/Protected';
import { AuthContextProvider } from './context/AuthContext';
import Account from './pages/Account';
import Home from './pages/Home';
import Signin from './pages/Signin';



import React, { useState, useEffect } from 'react';

import 'bootswatch/dist/sketchy/bootstrap.min.css'; // Added this :boom:
// TODO: Note: Replace ^[theme]^ (examples: darkly, slate, cosmo, spacelab, and superhero. See https://bootswatch.com for current theme names.)
import './App.css';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { auth } from './firebase';
import { Container, Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';
function App() {

  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  return (

    <AuthContextProvider>

      <Navbar />

      <Routes>

        <Route path='/' element={<Home />} />
        <Route path='/signin' element={<Signin />} />
        <Route
          path='/account'
          element={
            <Protected>


              <div className="container-fluid">

                <main role="main" className=" ">

                  <Account></Account>

                </main>

              </div>
            </Protected>
          }
        />

      </Routes>


    </AuthContextProvider >

  );
}

export default App;
