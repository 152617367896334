export const exampleStoryPlots = [
    "A courageous character in a lively world saves the day.",
    "An adventurous character in a whimsical setting learns to play an instrument.",
    "A determined character in a magical land overcomes a fear.",
    "A kind-hearted character in an enchanted forest solves a puzzling mystery.",
    "A playful character in an ancient realm learns a valuable lesson.",
    "A creative character in an imaginative world embarks on a thrilling journey.",
    "A persistent character in a bustling city starts a new hobby.",
    "A resourceful character in a mystical realm finds a lost treasure.",
    "An inquisitive character in a deep ocean begins a new adventure.",
    "A helpful character in a lush jungle discovers a hidden secret.",
    "A friendly character in a starry space learns the power of friendship.",
    "A patient character in a peaceful garden explores uncharted territories.",
    "An inventive character in a snowy mountain peak builds something incredible.",
    "A compassionate character at the rainbow’s end helps those in need.",
    "A tiny character at the royal castle embarks on a quest to find a missing item.",
    "A curious character in the vast desert encounters a mysterious artifact.",
    "A daring character in a quiet pond learns to play a musical instrument.",
    "A whimsical character at the sunny beach faces a unique challenge.",
    "A determined character in a windy meadow discovers a hidden treasure chest.",
    "An imaginative character in a deep forest goes on an epic adventure.",
    "A resilient character in a bustling city park organizes a community event.",
    "A clever character in a rainbow-colored world solves a complex riddle.",
    "An observant character in a peaceful garden helps bring about positive change.",
    "A spirited character in a tropical paradise learns the importance of perseverance.",
    "A wise character in an ancient realm shares wisdom with fellow inhabitants.",
    "A caring character in a colorful wonderland embarks on a heartwarming journey.",
    "A helpful character in an enchanted forest assists friends in need.",
    "A playful character in a mysterious land discovers the joy of discovery.",
    "An artistic character in an imaginative world creates a masterpiece.",
    "A persistent character in a lively world faces a unique challenge.",
    "A resourceful character in a whimsical setting helps bring about positive change.",
    "A courageous character in a magical land embarks on an epic quest.",
    "A kind-hearted character in an enchanted forest learns the value of compassion.",
    "A creative character in an ancient realm solves a difficult problem.",
    "A patient character in a bustling city park organizes a grand celebration.",
    "An imaginative character in a deep ocean explores the depths of the sea.",
    "A resilient character in a peaceful garden encounters a magical phenomenon.",
    "A clever character in a starry space learns to appreciate the beauty of the universe.",
    "An adventurous character in a lush jungle goes on an exciting expedition.",
    "A compassionate character in an imaginative world helps those in need.",
    "A daring character in an ancient kingdom faces a formidable adversary.",
];



export const exampleCharacters = [
    {
        "name": "Strawberry Starlight",
        "description": "Strawberry Starlight is a sweet and friendly strawberry character with a sparkling personality. Her red body is covered in tiny edible glitter stars, and her green leafy hat doubles as a magical wand. She loves to grant wishes to her friends with a wave of her leafy wand."
    },
    {
        "name": "Cupcake Charlie",
        "description": "Cupcake Charlie is a living cupcake who's always the life of the party. His frosting is colorful and can change flavors depending on his mood. He's known for throwing the most delicious and fun-filled celebrations in Candyland."
    },
    {
        "name": "Pineapple Pirate Pete",
        "description": "Pineapple Pirate Pete is an adventurous pineapple character who sails the Fruit Sea in search of hidden treasures. He wears a coconut shell hat, an eyepatch made of dried mango, and carries a pineapple sword. He's the captain of the juiciest crew on the high seas."
    },
    {
        "name": "Jellybean Jemma",
        "description": "Jellybean Jemma is a bouncy and energetic jellybean character. She's a rainbow of colors and can change her flavor at will. Jemma loves to bounce around Candyville, spreading happiness wherever she goes."
    },
    {
        "name": "Chocolate Chip Cookie Cody",
        "description": "Chocolate Chip Cookie Cody is a warm and gooey chocolate chip cookie with a heart of gold. He's always there to comfort his friends with his warm embrace and a glass of milk. His chocolate chips can even turn into tiny helpers when needed."
    },
    {
        "name": "Watermelon Wendy",
        "description": "Watermelon Wendy is a watermelon character who loves to keep everyone cool on hot summer days. She wears a sun hat made of watermelon rind and can spray refreshing watermelon juice to create instant watermelon pools for her friends."
    },
    {
        "name": "Lollipop Larry",
        "description": "Lollipop Larry is a living lollipop with a swirl of colors. He's the master of sweet tricks and can turn his stick into a..."
    },
    {
        "name": "Banana Bob",
        "description": "Banana Bob is a fun-loving banana character who's always up for an adventure. He wears a pair of cool sunglasses and can peel himself into a banana boat to float down the candy river."
    },
    {
        "name": "Blueberry Bella",
        "description": "Blueberry Bella is a tiny blueberry character with a big heart. She's known for her sweet and gentle nature and loves to share her delicious blueberries with her friends in Berryville."
    },
    {
        "name": "Ice Cream Izzy",
        "description": "Ice Cream Izzy is a scoop of ice cream with a colorful personality. She can change her flavors to match her mood and can even create ice cream tornadoes on hot summer days for some icy fun."
    },
    {
        "name": "Gingerbread George",
        "description": "Gingerbread George is a gingerbread man with a twist. He's an expert in gingerbread house construction and loves to create sweet and edible houses for his friends to live in."
    },
    {
        "name": "Pancake Patty",
        "description": "Pancake Patty is a stack of fluffy pancakes who's always warm and welcoming. She can pour syrup like a fountain and has a magical ability to make pancakes in any shape her friends desire."
    },
    {
        "name": "Honeybee Henry",
        "description": "Honeybee Henry is a friendly honeybee character who's the guardian of the Honey Meadows. He collects the sweetest nectar and protects it from candy-loving creatures."
    },
    {
        "name": "Popcorn Pete",
        "description": "Popcorn Pete is a popcorn kernel that's always ready for some popping action. He can pop into different shapes and sizes and loves to entertain his friends with popcorn fireworks."
    },
    {
        "name": "Cotton Candy Carly",
        "description": "Cotton Candy Carly is a fluffy and colorful cotton candy character. She can float on a cloud of cotton candy and create delicious cotton candy storms for her friends to enjoy."
    },
    {
        "name": "Pretzel Percy",
        "description": "Pretzel Percy is a twisty and salty pretzel character who's known for his witty sense of humor. He can twist and turn himself into various shapes and loves to tell pretzel-themed jokes."
    },
    {
        "name": "Cherry Cherry",
        "description": "Cherry Cherry is a pair of talking cherries who are always seen together. They finish each other's sentences and love to spread double the sweetness wherever they go."
    },
    {
        "name": "Gummy Bear Gabby",
        "description": "Gummy Bear Gabby is a colorful and squishy gummy bear who's full of energy. She loves to bounce around the Candy Forest and can stick to walls and ceilings with her gummy powers."
    },
    {
        "name": "Marshmallow Marvin",
        "description": "Marshmallow Marvin is a fluffy marshmallow character who's always soft and squishy. He loves to host marshmallow roasting parties and can change his flavor with a simple thought."
    },
    {
        "name": "Lemonade Larry",
        "description": "Lemonade Larry is a zesty lemon character with a talent for making the most refreshing lemonade. He can squirt lemon juice like a water gun and is known for his sour-sweet adventures."
    }
]

export const exampleCakes = [
    {
        name: "Birthday Bash Cake",
        description: "A vibrant and multi-tiered cake, perfect for a lively birthday celebration. It's adorned with colorful balloons, confetti, and a big 'Happy Birthday' message.",
    },
    {
        name: "Superhero Cake",
        description: "A cake designed like a favorite superhero's emblem, complete with action figures and edible glitter. Ideal for superhero-themed birthday parties.",
    },
    {
        name: "Princess Castle Cake",
        description: "This enchanting cake resembles a fairytale castle with pink icing, turrets, and sugar flowers. Fit for a princess-themed birthday party.",
    },
    {
        name: "Dinosaur Adventure Cake",
        description: "An adventurous cake featuring edible dinosaurs, palm trees, and a prehistoric landscape. Great for dinosaur-loving kids.",
    },
    {
        name: "Unicorn Wonderland Cake",
        description: "A magical cake decorated with edible unicorns, rainbows, and sparkling stars. Perfect for a whimsical unicorn-themed party.",
    },
    {
        name: "Under the Sea Cake",
        description: "Dive into the deep blue sea with this cake adorned with edible sea creatures, coral, and seashells. Ideal for an underwater adventure.",
    },
    {
        name: "Sports Fanatic Cake",
        description: "A cake designed with the logos of favorite sports teams, sports equipment, and a stadium backdrop. A hit at sports-themed parties.",
    },
    {
        name: "Chocolate Delight Cake",
        description: "A rich and indulgent chocolate cake with layers of chocolate ganache and chocolate shavings. A chocoholic's dream.",
    },
    {
        name: "Fruit Explosion Cake",
        description: "A fruity delight filled with fresh berries, kiwi, and tropical fruits. Light and refreshing for summer celebrations.",
    },
    {
        name: "Anniversary Elegance Cake",
        description: "A sophisticated two-tiered cake adorned with edible lace, pearls, and a romantic 'Love' topper. Ideal for anniversaries.",
    },
    {
        name: "Graduation Success Cake",
        description: "Celebrate academic achievements with a cake designed as a graduation cap, complete with a diploma. Perfect for graduates.",
    },
    {
        name: "Retirement Cheers Cake",
        description: "Bid farewell to a retiree with a cake featuring retirement-themed decorations, like a hammock and beach chair.",
    },
    {
        name: "Wedding Bliss Cake",
        description: "A classic white wedding cake with intricate floral piping and a bride and groom cake topper. Suitable for weddings and anniversaries.",
    },
    {
        name: "Baby Shower Cake",
        description: "Welcome a new addition with a cute cake decorated with baby booties, rattles, and a stork. Great for baby showers.",
    },
    {
        name: "Thanksgiving Harvest Cake",
        description: "Celebrate the harvest season with a cake adorned with edible pumpkins, leaves, and cornucopia. Perfect for Thanksgiving.",
    },
];
