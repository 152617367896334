import React, { useState, useEffect } from 'react';
import {
    getFirestore, collection, query, where, onSnapshot, orderBy, getDocs,
    doc, setDoc, updateDoc, addDoc, limit, deleteDoc
} from 'firebase/firestore';
import { db } from '../firebase'; // Import your Firebase config
import { Container, Row, Col, Card, Form, Button, Alert, Modal, Spinner } from 'react-bootstrap';
import { UserAuth } from '../context/AuthContext'; // Import your authentication context
import { exampleStoryPlots, exampleCharacters } from './examples'; // Adjust the path as necessary


import Filter from 'bad-words'; // A profanity filter library
import englishWordsList from 'an-array-of-english-words'; // An array of English words
import PricingPage from './PricingPage';
const filter = new Filter();

const CharacterGenerator = () => {
    const { user, logOut, userData } = UserAuth();
    const [characterName, setCharacterName] = useState('');
    const [characterDescription, setCharacterDescription] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [errorCharacterForm, setErrorCharacterForm] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Add loading state

    const handleShow = (character) => {
        setSelectedCharacter(character);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedCharacter(null);
    };
    const handleGenerateCharacter = async (e) => {
        e.preventDefault();

        // Call validateGenerateCharacter with characterName and characterDescription
        let isValid = validateGenerateCharacter(characterName, characterDescription);

        if (!selectedTemplate) {
            setErrorCharacterForm('Please select a character template.');
            return;
        }
        // Check the validation result
        if (!isValid) {
            // Validation failed, do not proceed with character generation
            return;
        }

        try {
            const utcTimestamp = Date.now();
            const charactersCollectionRef = collection(db, 'characters');
            const docRef = await addDoc(charactersCollectionRef, {
                characterName: characterName,
                characterDescription: characterDescription,
                timestamp: new Date(),
                author_Id: user.uid,
                selectedTemplate: selectedTemplate,
                status: 'Processing', // You can set the initial status as you like
                utc_createdAt: utcTimestamp,
            });
            setCharacterDescription(''); // Clear the character description input after successful submission.
            setCharacterName(''); // Clear the character name input after successful submission.
            setSuccessMessage(`The artist is now working on "${characterName}". Please be patient, it will take a few moments.`);

            setSelectedTemplate(''); // Reset to the default option
        } catch (error) {
            console.error("Error adding document: ", error);
            setErrorCharacterForm('An error occurred while generating the character. Please try again.');
        }

        // Clear any existing error if everything is valid
        setErrorCharacterForm('');
    };

    const validateGenerateCharacter = (name, description) => {
        // Check if the user has selected "Custom" but not provided name or descriptio

        if (selectedTemplate === 'Custom' && (!name || !description)) {
            setErrorCharacterForm('Please provide both a character name and description.');
            return false;
        }


        // Validate the name and description separately
        if (name) {
            // Validate the name for special characters and length
            if (!/^[a-zA-Z0-9\s.,'!@#$%^&*()-_+=<>?:"{}|[\]\\/]*$/.test(name)) {
                setErrorCharacterForm('Only letters, numbers, spaces, commas, periods, and special characters are allowed in the cake name.');
                return false;
            }

            if (name.length > 50) {
                setErrorCharacterForm('The character name must be at most 50 characters.');
                return false;
            }


            const words = name.trim().split(/\s+/);

            // Check each word against the dictionary
            // const allWordsValid = words.every(word => englishWordsList.includes(word.toLowerCase()));
            // if (!allWordsValid) {
            //     setErrorCharacterForm('Please enter meaningful words.');
            //     return false;
            // }

            // Check for profanity
            const isClean = !filter.isProfane(name);
            if (!isClean) {
                setErrorCharacterForm('Please avoid using profanity.');
                return false;
            }
        }


        if (description) {
            // Validate the description for special characters and length
            if (!/^[a-zA-Z0-9\s.,'!@#$%^&*()-_+=<>?:"{}|[\]\\/]*$/.test(name)) {
                setErrorCharacterForm('Only letters, numbers, spaces, commas, and full stops are allowed in the character description.');

                return false;
            }
            if (description.length > 300) {
                setErrorCharacterForm('The character description must be at most 200 characters.');
                console.log('fail');
                return false;
            }
            const words = description.trim().split(/\s+/);

            // Check each word against the dictionary
            // const allWordsValid = words.every(word => englishWordsList.includes(word.toLowerCase()));
            // if (!allWordsValid) {
            //     setErrorCharacterForm('Please enter meaningful words.');
            //     return false;
            // }

            // Check for profanity
            const isClean = !filter.isProfane(description);
            if (!isClean) {
                setErrorCharacterForm('Please avoid using profanity.');
                return false;
            }

        }



        // If all checks pass, clear any existing error and return true
        setErrorCharacterForm('');
        return true;
    };

    // Rest of the Character Generator component code
    // Function to handle document deletion
    const handleCharacterDelete = async (docId) => {
        if (window.confirm('Are you sure you want to delete this?')) {
            try {
                await deleteDoc(doc(db, 'characters', docId));
                // Optionally, handle any post-deletion logic here, like a success message
            } catch (error) {
                console.error("Error deleting document: ", error);
                // Optionally, handle any error logic here, like displaying an error message
            }
        }
    };

    const handleTemplateChange = (e) => {
        const selectedTemplateName = e.target.value;
        setSelectedTemplate(selectedTemplateName);


        // Find the selected template character and set the characterName and characterDescription accordingly
        const selectedCharacter = exampleCharacters.find((char) => char.name === selectedTemplateName);


        if (selectedCharacter) {
            setCharacterName(selectedCharacter.name);
            setCharacterDescription(selectedCharacter.description);
        } else {
            // Handle the case when the template is not found
            setCharacterName('');
            setCharacterDescription('');
        }
    };

    const handleNameChange = (e) => {
        setCharacterName(e.target.value);
    };

    const handleCharacterDescriptionChange = (e) => {
        setCharacterDescription(e.target.value);
    };


    // Add a state variable to store user characters
    const [userCharacters, setUserCharacters] = useState([]);

    const [selectedCharacter, setSelectedCharacter] = useState(null);



    const characterPages = [];
    for (let i = 0; i < userCharacters.length; i += 3) {
        characterPages.push(userCharacters.slice(i, i + 3));
    }

    const renderCharacterCards = () => {
        return (
            <div className="d-flex flex-wrap">
                {userCharacters.map((character) => (
                    <div key={character.id} className="col-6 col-md-4 col-lg-3 mb-4">
                        <Card
                            className={`character-card ${selectedCharacter?.id === character.id ? 'selected' : ''}`}
                            style={{ height: '100%', overflow: 'hidden' }}
                        >
                            {isLoading ? ( // Show loading spinner when loading
                                <Spinner animation="border" role="status" className="m-auto mt-4">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            ) : (
                                <>

                                    {character.download_link ? ( // Show buttons if download link is available
                                        <Card.Img variant="top" src={character.download_link} alt={character.characterName} />
                                    ) : (
                                        // Show a loading animation if download link is not available
                                        <Spinner animation="border" role="status" className="m-auto mt-4">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    )}



                                    <Card.Body className="d-flex flex-column" style={{ height: '100%' }}>
                                        <Card.Text className="text-center small p-0">{character.characterName}</Card.Text>

                                    </Card.Body>
                                    {character.download_link ? ( // Show buttons if download link is available
                                        <Card.Footer className="d-flex flex-column " style={{ height: '100%' }}>

                                            <div className="d-flex justify-content-between">

                                                <Button variant="danger" className='btn btn-xs' onClick={() => handleCharacterDelete(character.id)}>
                                                    <i className="fa fa-trash"></i>
                                                </Button>
                                                <Button
                                                    variant="outline-primary"
                                                    size="xs"
                                                    className="btn btn-outline-info btn-xs mb-0 "
                                                    onClick={() => handleShow(character)}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </Button>

                                                <a
                                                    href={character.download_link}
                                                    download={`${character.characterName}.jpg`}
                                                    className="btn btn-outline-info btn-xs mb-0"
                                                >
                                                    <i className="fa fa-download"></i>
                                                </a>

                                            </div>
                                        </Card.Footer>
                                    ) : <Card.Footer className="d-flex flex-column " ><div className="d-flex justify-content-between"><Button variant="danger" className='btn btn-xs' onClick={() => handleCharacterDelete(character.id)}>
                                        <i className="fa fa-trash"></i>
                                    </Button></div> </Card.Footer>}
                                </>
                            )}
                        </Card>
                    </div>
                ))}
            </div>
        );
    };




    useEffect(() => {
        // Create a Firestore query to get the current user's characters
        const q = query(
            collection(db, 'characters'), // Replace 'characters' with your Firestore collection name
            where('author_Id', '==', user?.uid), // Replace 'author_Id' with the field that represents the author's ID
            orderBy('utc_createdAt', 'desc') // Replace 'utc_createdAt' with your timestamp field name
        );

        // Subscribe to the query and update userCharacters when data changes
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const charactersData = [];
            snapshot.forEach((doc) => {
                charactersData.push({ id: doc.id, ...doc.data() });
            });
            setUserCharacters(charactersData);
        });

        return unsubscribe; // Unsubscribe from updates when the component unmounts
    }, [user?.uid]);



    return (




        <Container className="my-3" >
            {/* <Container className="my-3" style={myStyles}></Container> */}
            <Row>
                <Col>
                    {successMessage && (
                        <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
                            {successMessage}
                        </Alert>
                    )}

                </Col>
            </Row>

            {
                userData?.status === 'blocked' ? (
                    <>
                        <Alert variant="primary">
                            Your account is locked as the trial threshold has been reached. To continue using our service, please subscribe now.
                        </Alert>
                        <PricingPage />
                    </>
                ) : null
            }
            <Row className="mt-4" >



                <Col md={4} className="text-center">

                    <p className="text-left" >
                        Choose a catchy name that reflects your character's personality and traits.
                        <strong> Description & Special Abilities:</strong> Describe your character's appearance, personality, and unique features to engage young readers' imaginations. Mention any special talents or abilities to add excitement to their adventures in the story.
                    </p>

                    <Form onSubmit={handleGenerateCharacter}>
                        <Form.Group className="mb-3">


                            <Form.Control as="select" onChange={handleTemplateChange} value={selectedTemplate}>

                                <option value="">Choose a character template </option>

                                <option value="Custom">Custom</option> {/* Move "Custom" option to the top */}
                                {exampleCharacters.map((char) => (
                                    <option key={char.name} value={char.name}>
                                        {char.name}
                                    </option>
                                ))}
                            </Form.Control>

                            {selectedTemplate !== 'Custom' && (
                                <>
                                    <Form.Control
                                        as="input"
                                        type="text"
                                        className='mt-4'
                                        rows={1}
                                        maxLength={50}
                                        placeholder="Enter your character name (max 50)"
                                        value={characterName}
                                        readOnly={true} // Make the name field read-only
                                    />

                                    <Form.Control
                                        as="textarea"
                                        rows={2}
                                        className='mt-4'
                                        maxLength={200}
                                        placeholder="Enter your character description (max 200)"
                                        value={characterDescription}
                                        readOnly={true} // Make the description field read-only
                                    />
                                </>
                            )}

                            {selectedTemplate === 'Custom' && (
                                <>
                                    <Form.Control
                                        as="input"
                                        type="text"
                                        className='mt-4'
                                        rows={1}
                                        maxLength={50}
                                        placeholder="Enter your character name (max 50)"
                                        value={characterName}
                                        onChange={handleNameChange}
                                        isInvalid={!!errorCharacterForm}
                                    />

                                    <Form.Control
                                        as="textarea"
                                        rows={2}
                                        className='mt-4'
                                        maxLength={200}
                                        placeholder="Enter your character description (max 200)"
                                        value={characterDescription}
                                        onChange={handleCharacterDescriptionChange}
                                        isInvalid={!!errorCharacterForm}
                                    />
                                </>
                            )}

                            <Form.Control.Feedback type="invalid">
                                {errorCharacterForm}
                            </Form.Control.Feedback>
                        </Form.Group>
                        {
                            userData?.status === 'blocked' ? (
                                <Alert variant="danger">
                                    Your account is locked as the trial threshold has been reached. For assistance, please contact <a href="mailto:info@doctran.ai">info@doctran.ai</a>.
                                </Alert>
                            ) : (
                                <Button variant="" className="btn btn-xl btn-outline-success" type="submit">
                                    Generate Character {/* Make sure this button is of type "submit" */}
                                </Button>)
                        }
                    </Form>

                </Col>
                <Col md={8}>

                    {/* Render the character carousel below the story plot */}
                    {userCharacters.length > 0 && (
                        <div>
                            {renderCharacterCards()}
                            {/* Modal to display the selected character image */}
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    {selectedCharacter && (
                                        <Modal.Title>{selectedCharacter.characterName}</Modal.Title>
                                    )}
                                </Modal.Header>

                                <Modal.Body>
                                    {selectedCharacter && (
                                        <>
                                            {isLoading ? ( // Show loading spinner when loading
                                                <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            ) : (
                                                <img
                                                    src={selectedCharacter.download_link}
                                                    alt={selectedCharacter.characterName}
                                                    style={{ width: '100%', height: 'auto' }}
                                                />
                                            )}
                                        </>
                                    )}
                                </Modal.Body>
                                <Modal.Footer className="d-flex justify-content-between">

                                    <Button className="btn btn-outline btn-xs" onClick={handleClose}>
                                        Close
                                    </Button>

                                    {selectedCharacter && selectedCharacter.download_link ? (
                                        <a
                                            href={selectedCharacter.download_link}
                                            download={`${selectedCharacter.characterName}.jpg`}
                                            className="btn btn-outline-info btn-xs"
                                        >
                                            <i className="fa fa-download"></i> Download
                                        </a>
                                    ) : null}
                                </Modal.Footer>

                            </Modal>
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default CharacterGenerator;
