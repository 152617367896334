import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your Firebase config object, which you'd get from your Firebase project settings.
const firebaseConfig = {
    apiKey: "AIzaSyCZuiB0lViEMkB1egjI-ckfIA25KZhs9u0",
    authDomain: "ai-storytime-d01bd.firebaseapp.com",
    projectId: "ai-storytime-d01bd",
    storageBucket: "ai-storytime-d01bd.appspot.com",
    messagingSenderId: "271134273428",
    appId: "1:271134273428:web:ecd0dda8173f2896236981"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { auth, app, db };


