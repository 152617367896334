import React, { useState, useEffect } from 'react';
import { UserAuth } from '../context/AuthContext';
import { db } from '../firebase';
import {
    getFirestore, collection, query, where, onSnapshot, orderBy, getDocs,
    doc, setDoc, updateDoc, addDoc, limit, deleteDoc
} from 'firebase/firestore';
import { Container, Row, Col, Card, Form, Button, Alert, Table, Modal, Spinner } from 'react-bootstrap';
import Filter from 'bad-words'; // A profanity filter library
import englishWordsList from 'an-array-of-english-words'; // An array of English words
import { exampleStoryPlots } from '../components/examples'; // Adjust the path as necessary
import PricingPage from './PricingPage';

const filter = new Filter();
const StoryGenerator = () => {
    // const myStyles = {
    //   fontFamily: 'Patrick Hand, cursive',
    // };
    const { user, logOut, userData } = UserAuth();
    const [successMessage, setSuccessMessage] = useState('');

    // Add a state variable to track the selected character
    const [selectedCharacterforPlot, setSelectedCharacterForPlot] = useState(null);

    // Function to handle character selection
    const handleCharacterClickforPlot = (character) => {
        console.log(character);
        setSelectedCharacterForPlot(character);
    };

    const [plot, setPlot] = useState('');
    const [plots, setPlots] = useState([]);
    const [error, setError] = useState('');


    const [characterName, setCharacterName] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [characterDescription, setCharacterDescription] = useState('');



    // State to hold the current plot example
    const [randomPlot, setRandomPlot] = useState(exampleStoryPlots[0]);

    // Function to update the randomPlot with a new random example
    const rotatePlot = () => {
        const nextPlot = exampleStoryPlots[Math.floor(Math.random() * exampleStoryPlots.length)];
        setRandomPlot(nextPlot);
    };
    // Inside your component

    const [show, setShow] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalTitle, setModalTitle] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (content, title) => {
        if (Array.isArray(content)) {
            // If the content is an array, join it into a single string or handle appropriately
            setModalContent(content.join(' ')); // or you could map through it in your modal body
        } else {
            // If the content is a string, just set it
            setModalContent(content);
        }
        setModalTitle(title); // Set the title for the modal based on what content is being shown
        setShow(true);
    };

    useEffect(() => {
        setRandomPlot(exampleStoryPlots[Math.floor(Math.random() * exampleStoryPlots.length)]);
    }, []); // The empty array ensures this only runs once when the component mounts


    const plotsCollectionRef = collection(db, "plots");

    useEffect(() => {
        // Query to get the current user's plots
        const q = query(plotsCollectionRef, where("author_Id", "==", user?.uid), orderBy("utc_createdAt", "desc"));
        // Real-time subscription to updates
        const unsubscribe = onSnapshot(q, (snapshot) => {
            setPlots(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        });

        return unsubscribe; // Unsubscribe from updates when the component unmounts
    }, [user?.uid]);

    const handlePlotChange = (e) => {
        setPlot(e.target.value);
    };

    const handleCharacterDescriptionChange = (e) => {
        setCharacterDescription(e.target.value);
    };


    const handleNameChange = (e) => {
        setCharacterName(e.target.value);
    };




    const validatePlot = (text) => {
        // Split the text into words
        const words = text.trim().split(/\s+/);

        // Check if a character is selected
        if (!selectedCharacterforPlot) {
            setError('Please select a character before submitting the plot.');
            return false;
        }


        // Check for special characters and minimum three words
        const wordCount = words.length;
        if (!/^[a-zA-Z0-9\s.,'!@#$%^&*()-_+=<>?:"{}|[\]\\/]*$/.test(text)) {
            setError('Only letters, numbers, spaces, commas, periods, and special characters are allowed in the cake name.');
            return false;
        }
        else if (wordCount < 3) {
            setError('The plot must be at least three words.');
            return false;
        }



        // Check each word against the dictionary
        // const allWordsValid = words.every(word => englishWordsList.includes(word.toLowerCase()));
        // if (!allWordsValid) {
        //     setError('Please enter meaningful words.');
        //     return false;
        // }

        // Check for profanity
        const isClean = !filter.isProfane(text);
        if (!isClean) {
            setError('Please avoid using profanity.');
            return false;
        }

        // If all checks pass, clear any existing error and return true
        setError('');
        return true;
    };




    const handleCreatePlot = async (e) => {
        e.preventDefault();

        // Validate the plot once and use its return value to decide the next step.
        if (!validatePlot(plot)) {
            // The error message is already set inside `validatePlot`, so no need to set it here again.
            return;
        }

        // If the plot is valid, proceed to create a new document in the 'plots' collection.
        try {
            const utcTimestamp = Date.now();
            const docRef = await addDoc(plotsCollectionRef, {
                plot_text: plot,
                timestamp: new Date(),
                author_Id: user.uid,
                status: 'Processing ...(5 to 10 min)', // You can set the initial status as you like
                utc_createdAt: utcTimestamp, // added this line
                characterName: selectedCharacterforPlot.characterName, // added this line
                characterDescription: selectedCharacterforPlot.characterDescription
            });
            setPlot(''); // Clear the plot input after successful submission.
            setSuccessMessage('Plot generated successfully. Processing will take 5 to 10 minutes to generate all assests.'); // Set success message
        } catch (error) {
            console.error("Error adding document: ", error);
            setError('An error occurred while adding the plot. Please try again.'); // Set an error message for the user.
        }
    };


    // Function to handle document deletion
    const handleDelete = async (docId) => {
        if (window.confirm('Are you sure you want to delete this plot and all assets?')) {
            try {
                await deleteDoc(doc(db, 'plots', docId));
                // Optionally, handle any post-deletion logic here, like a success message
            } catch (error) {
                console.error("Error deleting document: ", error);
                // Optionally, handle any error logic here, like displaying an error message
            }
        }
    };



    // Add a state variable to store user characters
    const [userCharacters, setUserCharacters] = useState([]);

    const [selectedCharacter, setSelectedCharacter] = useState(null);

    const handleCharacterClick = (character) => {
        setSelectedCharacter(character);
    };

    const characterPages = [];
    for (let i = 0; i < userCharacters.length; i += 3) {
        characterPages.push(userCharacters.slice(i, i + 3));
    }

    // Function to render character cards
    // const renderCharacterCards = () => {
    //     return (
    //         <div className="character-carousel-container">
    //             <div className="character-list">
    //                 {userCharacters.map((character) => (
    //                     <Card
    //                         key={character.id}
    //                         className={`character-card ${selectedCharacter?.id === character.id ? 'selected' : ''
    //                             }`}
    //                         onClick={() => handleCharacterClick(character)}
    //                     >
    //                         <Card.Img
    //                             variant="top"
    //                             src={character.download_link}
    //                             alt={character.characterName}
    //                         />
    //                         <Card.Body className="d-flex flex-column" style={{ height: '100%' }}>
    //                             <Card.Text className="text-center small p-0 text-truncate">{character.characterName}</Card.Text>

    //                         </Card.Body>
    //                     </Card>
    //                 ))}
    //             </div>
    //         </div>

    //     );
    // };

    // Function to render character cards
    const renderCharacterCards = () => {
        return (
            <div className="character-carousel-container">
                <div className="character-list">
                    {userCharacters.map((character) => (
                        <Card
                            key={character.id}
                            className={`character-card ${selectedCharacterforPlot?.id === character.id ? 'selected' : ''
                                }`}
                            onClick={() => handleCharacterClickforPlot(character)}
                        >

                            {character.download_link ? ( // Show buttons if download link is available
                                <Card.Img
                                    variant="top"
                                    src={character.download_link}
                                    alt={character.characterName}
                                />
                            ) : (
                                // Show a loading animation if download link is not available
                                <Spinner animation="border" role="status" className="m-auto mt-4">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            )}

                            <Card.Body className="d-flex flex-column" style={{ height: '100%' }}>
                                <Card.Text className="text-center small p-0 text-truncate">{character.characterName}</Card.Text>
                            </Card.Body>
                        </Card>
                    ))}
                </div>
            </div>
        );
    };

    useEffect(() => {
        // Create a Firestore query to get the current user's characters
        const q = query(
            collection(db, 'characters'), // Replace 'characters' with your Firestore collection name
            where('author_Id', '==', user?.uid), // Replace 'author_Id' with the field that represents the author's ID
            orderBy('utc_createdAt', 'desc') // Replace 'utc_createdAt' with your timestamp field name
        );

        // Subscribe to the query and update userCharacters when data changes
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const charactersData = [];
            snapshot.forEach((doc) => {
                charactersData.push({ id: doc.id, ...doc.data() });
            });
            setUserCharacters(charactersData);
        });

        return unsubscribe; // Unsubscribe from updates when the component unmounts
    }, [user?.uid]);


    // Rest of the Story Generator component code

    return (
        <Container>
            <Row>
                <Col md={12} className="text-center">

                    <h6 className=" my-4 text-left mb-0">Step 1: Select Character
                        {selectedCharacterforPlot && (
                            <span className="font-weight-bold text-color fw-bold"> : {selectedCharacterforPlot.characterName} </span>
                        )}
                    </h6>



                    {userCharacters.length > 0 ? (
                        <div>
                            {renderCharacterCards()}
                        </div>
                    ) : (
                        <div className='mt-4'>
                            <p>No characters found. You can create a character in the Characters tab.</p>
                        </div>
                    )}


                    <h6 className=" my-4 text-left mb-2">Step 2: Story Plot

                        {selectedCharacterforPlot && (
                            <span className="font-weight-bold text-color fw-bold"> : {selectedCharacterforPlot.characterName} </span>
                        )}

                    </h6>


                    <Form onSubmit={handleCreatePlot}>
                        <Form.Group className="mb-3">
                            <Form.Control
                                as="textarea"
                                rows={2}
                                maxLength={200}
                                placeholder="Enter your story plot (max 200 )"
                                value={plot}
                                onChange={handlePlotChange}
                                isInvalid={!!error}
                            />
                            <Form.Control.Feedback type="invalid">
                                {error}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <p className='text-2xl font-bold text-small text-left mt-2'>
                            <small>
                                <span>
                                    When submitting a plot for a children's story, think simple and delightful. For instance,

                                    <button className='btn btn-xs' onClick={rotatePlot} style={{ marginLeft: '10px', padding: '0 5px' }}>
                                        <i className="fas fa-sync"></i>
                                    </button>

                                    <strong>"{randomPlot}"</strong>  is perfect. Short plots are best, as they'll be expanded into stories with clear language suitable for kids aged 3 to 5.
                                </span>


                                {/* <span>
                                    When submitting a plot for a children's story, think simple and delightful. Short plots are best, as they'll be expanded into stories with clear language suitable for kids aged 3 to 5.
                                </span> */}
                            </small>
                        </p>

                        {
                            userData?.status === 'blocked' ? (
                                <>
                                    <Alert variant="primary">
                                        Your account is locked as the trial threshold has been reached. To continue using our service, please subscribe now.
                                    </Alert>
                                    <PricingPage />
                                </>
                            ) : (
                                <Button variant="success" className="btn btn-xl btn-success" type="submit">
                                    Generate Video Story
                                </Button>
                            )
                        }







                    </Form>
                </Col>

            </Row>
            <Row className="mt-4">
                <Col>

                    <Table className="table table-hover">
                        <thead>
                            <tr>
                                <th><small>Date</small></th>
                                <th  ><small>Plot</small></th>
                                <th className="text-center"><small>Character</small></th>
                                <th className="text-center"><small>Plot & Story</small></th>
                                <th><small>Story Title</small></th>
                                <th className="text-center"><small>Status</small></th>
                                <th className="text-center"><small>Download</small> </th>
                                <th> </th>
                            </tr>
                        </thead>
                        <tbody>
                            {plots.map((p) => (
                                <tr key={p.id}>
                                    <td style={{ width: "10%" }}><small>{p.timestamp.toDate().toLocaleString()}</small></td>
                                    <td className="text-truncate" style={{ width: "10%" }}><small>{p.plot_text}</small></td>

                                    <td style={{ width: "10%" }} className="text-center">
                                        {/* Check if the refined plot content is available */}
                                        <small> {p.characterName}</small>
                                    </td>
                                    <td className="text-center" style={{ width: "20%" }}>
                                        {/* Check if the story content is available */}

                                        {/* Check if the refined plot content is available */}
                                        {p.refined_plot &&
                                            <Button variant="" className='btn btn-xs btn-outline-success' onClick={() => handleShow(p.refined_plot, 'Refined Plot')}>
                                                Refined Polot
                                            </Button>
                                        }
                                        {p.story &&
                                            <Button variant="" className='btn btn-xs btn-outline-success' onClick={() => handleShow(p.story, 'Story')}>
                                                View Story
                                            </Button>
                                        }
                                    </td>
                                    <td style={{ width: "20%" }}><small>{p.story_title}</small></td>
                                    <td style={{ width: "20%" }} className="text-center"><small>{p.status}</small>
                                        {
                                            p.status === 'success' ? (
                                                <span className="badge badge-pill badge-success badge-tick ml-1">
                                                    <i className="fas fa-check"></i>
                                                </span>
                                            ) : p.status === "Your calls are rejected as your account is temporarily locked. Please contact info@doctran.ai" ? (
                                                <span className="badge badge-pill badge-danger ml-1">
                                                    <i className="fas fa-ban"></i> {/* or 'fa-ban' or another appropriate icon */}
                                                </span>
                                            ) : (
                                                <span className="badge badge-pill badge-warning badge-tick ml-1">
                                                    <i className="fas fa-cog fa-spin"></i>
                                                </span>
                                            )
                                        }

                                    </td>
                                    <td className="text-center">
                                        {/* Check if the download link is available */}
                                        {p.download_link &&
                                            <a href={p.download_link} download className='btn btn-xs btn-outline-success'>
                                                <i className="fa fa-download"></i> Download
                                            </a>
                                        }
                                    </td>
                                    <td className="text-center">
                                        {/* Delete button */}
                                        <Button variant="danger" className='btn btn-xs' onClick={() => handleDelete(p.id)}>
                                            <i className="fa fa-trash"></i>
                                        </Button>
                                    </td>

                                </tr>

                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            {/* Modal for displaying the content */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* If you want to list array elements separately */}
                    {Array.isArray(modalContent) ? (
                        modalContent.map((item, index) => <p key={index}>{item}</p>)
                    ) : (
                        <p>{modalContent}</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </Container >
    );
};

export default StoryGenerator;
