import React from 'react';

const features = [
    {
        title: "AI-Powered Plot Refinement",
        description: "Submit your story idea, and our AI transforms it into a polished tale."
    },
    {
        title: "Custom Title Generation",
        description: "Catchy and unique titles that draw children into the wonder of the story."
    },
    {
        title: "Captivating Cover Images",
        description: "Engage young readers from the start with beautifully generated cover art."
    },
    {
        title: "Dynamic Story Generation",
        description: "Watch your ideas come to life as our AI weaves them into a compelling narrative for children."
    },
    {
        title: "Audio Voiceover MP3",
        description: "Bring stories to life with engaging voiceovers, perfect for creating an auditory experience for young listeners."
    },
    {
        title: "Unique Graphic Generation",
        description: "Each story is paired with its own set of unique, AI-generated graphics to captivate the visual senses."
    },
    {
        title: "Customized Book Covers",
        description: "Create a stunning first impression with custom book covers that capture the essence of your tale."
    },
    {
        title: "MP4 Video Generation",
        description: "Seamlessly transform your stories into videos, ready to publish on YouTube or for download, expanding your reach."
    }
];


const FeatureCard = ({ title, description }) => (
    <div className="card" style={{ width: '18rem', marginBottom: '1rem' }}>
        <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">{description}</p>
        </div>
    </div>
);

const FeatureList = () => (
    <div className="container">
        <div className="row">
            {features.map((feature, index) => (
                <div className="col-sm-12 col-md-3 col-lg-3" key={index}>
                    <FeatureCard title={feature.title} description={feature.description} />
                </div>
            ))}
        </div>
    </div>
);

export default FeatureList;
