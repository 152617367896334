import * as React from 'react';

function PricingPage() {
    // Paste the stripe-pricing-table snippet in your React component
    return (
        <stripe-pricing-table pricing-table-id="prctbl_1OE5xlJ9SO2Jx1Ct3aYifCwl"
            publishable-key="pk_live_51ODuaAJ9SO2Jx1CtLnJqF2291tu0498HodM1bDqkAesVHWrLJLahq4lgIgXoEeLw8WfjVTe0JdBwxDLMDZ9WKlPG00QUjhCLrJ">
        </stripe-pricing-table>
    );
}

export default PricingPage;