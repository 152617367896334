import React, { useEffect, useState } from 'react';
import { GoogleButton } from 'react-google-button';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { Container, Row, Col, Card, Alert, Form, Button } from 'react-bootstrap';

const Signin = () => {
  const { googleSignIn, user } = UserAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [industry, setIndustry] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [signUpError, setSignUpError] = useState(null);
  const [signInError, setSignInError] = useState(null);
  const [isNewUser, setIsNewUser] = useState(true);

  const industries = [
    "Agriculture", "Automotive", "Construction", "Finance", "Healthcare",
    "Information Technology", "Retail", "Transportation", "Other"
  ];

  useEffect(() => {
    if (user != null) {
      navigate('/account');
    }
  }, [user, navigate]);

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      setSignInError(error.message);
    }
  };

  const handleSubmit = (formType) => async (e) => {
    e.preventDefault();

    const auth = getAuth();

    if (formType === 'newUser') {
      // Handle new user sign-up
      setSignUpError(null);
      if (newUserEmail !== confirmEmail) {
        setSignUpError("Emails do not match!");
        return;
      }
      if (newUserPassword !== confirmPassword) {
        setSignUpError("Passwords do not match!");
        return;
      }

      try {
        const userCredential = await createUserWithEmailAndPassword(auth, newUserEmail, newUserPassword);
        const db = getFirestore();
        const userRef = doc(db, "users", userCredential.user.uid);
        await setDoc(userRef, {
          fullName: fullName,
          industry: industry,
          companySize: companySize,
          phoneNumber: phoneNumber,
          email: newUserEmail
        });
        navigate('/account');
      } catch (err) {
        setSignUpError(err.message.replace('Firebase:', '').trim());
      }
    } else if (formType === 'signIn') {
      // Handle existing user sign-in
      setSignInError(null);
      try {
        await signInWithEmailAndPassword(auth, email, password);
        navigate('/account');
      } catch (err) {
        setSignInError(err.message.replace('Firebase:', '').trim());
      }
    }
  };



  return (
    <div className="text-dark justify-content-center align-items-center bg-light" >

      <Container className='mt-4'>

        <Row>

          {/* Sign-in Form */}
          <Col md={6} className='mt-4'>
            <Card bg="" className="p-4" >
              <Card.Title className="text-center mb-4">Already have an account? Sign In</Card.Title>
              <p>  {signInError && <Alert variant="danger" style={{ padding: '0.5rem 1rem' }}>{signInError}</Alert>}</p>
              <Form onSubmit={handleSubmit('signIn')}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="btn btn-success mt-3" style={{ display: 'block', margin: 'auto' }}>
                  Sign In
                </Button>
                <div className="text-center mt-3">
                  <GoogleButton style={{ display: 'block', margin: 'auto' }} onClick={handleGoogleSignIn} />
                </div>
              </Form>
            </Card>
          </Col>
          {/* Sign-up Form */}

          <Col md={6} className='mt-4'>
            <Card bg="" className="p-4" >
              <Card.Title className="text-center mb-4">Sign up for free</Card.Title>
              <p> {signUpError && <Alert variant="danger" style={{ padding: '0.5rem 1rem' }}>{signUpError}</Alert>}</p>
              <Form onSubmit={handleSubmit('newUser')}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Full Name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="select"
                    value={industry}
                    onChange={(e) => setIndustry(e.target.value)}
                    required
                  >
                    <option value="">Select Industry</option>
                    {industries.map(ind => (
                      <option key={ind} value={ind}>{ind}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="select"
                    value={companySize}
                    onChange={(e) => setCompanySize(e.target.value)}
                    required
                  >
                    <option value="">Select Company Size</option>
                    <option value="1-10">1-10</option>
                    <option value="10-50">10-50</option>
                    <option value="50+">50+</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Phone Number with Country Code"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    value={newUserEmail}
                    onChange={(e) => setNewUserEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder="Confirm Email Address"
                    value={confirmEmail}
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={newUserPassword}
                    onChange={(e) => setNewUserPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" style={{ display: 'block', margin: 'auto' }} className="btn btn-success  mt-3">
                  Sign Up
                </Button>
              </Form>
            </Card>
          </Col>


        </Row>
      </Container>
    </div>
  );
};

export default Signin;
