import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { Navbar, Container } from 'react-bootstrap';

const Navigation = () => {
    const { user, logOut } = UserAuth();
    const location = useLocation(); // Hook to access the current location

    const handleSignOut = async () => {
        try {
            await logOut();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Navbar sticky="top" className="flex-md-nowrap bg-light" data-bs-theme="light">
            <Container className='full-width-nav' style={{ maxWidth: '100% !important' }}>
                <Navbar.Brand href="/">
                    <img src="android-chrome-192x192.png" width="30" height="30" className="d-inline-block align-top mr-1" alt="" />
                    DOCTRAN.ai <small className='d-none d-sm-block' style={{ fontSize: 12 }}>Automate | Innovate | Accelerate</small>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text >
                        {user?.uid && location.pathname !== '/account' && (
                            <Link type="button" className="btn btn-outline-dark my-2 my-sm-0 btn-sm mr-2" to='/account'>Studio</Link>
                        )}
                        {user?.uid ? (
                            <button className="btn btn-outline-dark my-2 my-sm-0 btn-sm" onClick={handleSignOut}>Logout</button>
                        ) : (
                            <Link type="button" className="btn  btn-outline-dark my-2 my-sm-0 btn-sm" to='/signin'>Sign in</Link>
                        )}
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navigation;
