import React, { useState, useEffect } from 'react';
import { UserAuth } from '../context/AuthContext'; // Import your authentication context
import { collection, onSnapshot, doc, deleteDoc } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../firebase'; // Import your Firebase config
import StoryGenerator from '../components/StoryGenerator';
import CharacterGenerator from '../components/CharacterGenerator';
import CakeGenerator from '../components/CakeGenerator';
import { Container, Nav, Tab } from 'react-bootstrap'; // Import React Bootstrap components

const Account = () => {
  const { user, logOut, userData } = UserAuth();
  const [plots, setPlots] = useState([]);
  const [selectedCharacter, setSelectedCharacter] = useState(null);

  useEffect(() => {
    // Query to get the current user's plots
    const q = collection(db, "plots");
    // Real-time subscription to updates
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setPlots(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    });

    return unsubscribe; // Unsubscribe from updates when the component unmounts
  }, []);

  return (

    <Container >
      <div className="mt-4">
        {/* <h2 className='mt-3 mb-3'>Creative Studio</h2> */}
        <Tab.Container id="account-tabs" defaultActiveKey="story" >
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="story"><h5>Story Generator</h5></Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="character"><h5>Character Generator</h5></Nav.Link>
            </Nav.Item>
            <Nav.Item >
              <Nav.Link eventKey="cake" ><h5>Cake Generator</h5></Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="story">
              <StoryGenerator />
            </Tab.Pane>
            <Tab.Pane eventKey="character">
              <CharacterGenerator />
            </Tab.Pane>
            <Tab.Pane eventKey="cake">
              <CakeGenerator />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        {/* Rest of the Account content */}
      </div>
    </Container>
  );
};

export default Account;
